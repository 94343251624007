import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { LoadingService } from './loading.service';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CreateAccountService {
  http = inject(HttpClient);
  private loadingService = inject(LoadingService);

  BASE_URL = environment.CLIENT_BASE_URL + '/v1/account';

  createAccount(payload: {
    account_type: 'individual' | 'corporate';
    profile_id: string | null;
  }) {
    const uniqueStateUrl = 'create-account';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post(`${this.BASE_URL}/create-account`,
        payload,
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
}
